<script setup>
    import { ref, watch } from 'vue';
    import IFrame from 'o365.vue.components.Iframe.vue';
    import { getOrCreateProcedure } from 'o365-modules';
    import { BimViewer } from 'bim-viewer'

    /* VARIABLES */
    const procGetBOJson = getOrCreateProcedure({ id: "procGetBOJson", procedureName: "astp_Lease_GetRoomsBIMObjectsJson2", useGroupedRequests: false });
    const vIframe = ref(null);
    const useIframe = ref(false);
    // const vIframeLoaded = ref(false);
    const vGroupColors = ref(null);
    const props = defineProps({ vModelID: Number,
                                vFieldName: {type: String, default: null},
                                vFilter: String,
                                vSelectedObjectIDs: {type: String, default: '[]'},
                                vVisMode: {type: String, default: 'default'},
                                vMapMode: {type: Boolean, default: false},
                                vDS: Object
    });
    const vSelectedRows = ref([]);
    const vSelectingFromBIM = ref(false);

    const roomRegisterState = ref(null);
    // const bimViewerRendered = ref(false);

    const emit = defineEmits(['groupColorsAdded'])
    let vJsonData = null;
    let vFragments = null;
    let vIfcObjectIDs = null;

    // window.addEventListener('message', (pObj)=>{
        // if(pObj.data.event == "onReady") {
            // vIframeLoaded.value = true;
            // loadBimData();
        // }
        // if(pObj.data.event == "onObjectSelected") {
            // onObjectSelected(pObj.data.content);
        // }
    // });

    /* WATCH */
    // watch(() => props.vModelID, (first, second) => {
        // // if (props.vModelID) {
            // // bimViewerRendered.value = true;
        // // }
        // console.log("Watch 1");
        // // loadBimData();
    // });

    watch(() => props.vFieldName, (first, second) => {
        console.log("Watch 2");
        loadBimData();
    });

    watch(() => props.vFilter, (first, second) => {
        console.log("Watch 3");
        loadBimData();
    });

    watch(() => props.vSelectedObjectIDs, (first, second) => {
        window["vSelectedObjectIDs"] = props.vSelectedObjectIDs;
        if(!vSelectingFromBIM.value) {
            vSelectedRows.value = JSON.parse(props.vSelectedObjectIDs);
            console.log("Watch 4");
            loadBimData();
        }
    });

    // watch(() => props.vVisMode, (first, second) => {
        // console.log("Watch 5");
        // loadBimData();
    // });

    // watch(() => props.vMapMode, (first, second) => {
        // console.log("Watch 6");
        // loadBimData();
    // });

    /* FUNCTIONS */
    async function setSelectedObjects(pObjectIDs) {
        vSelectingFromBIM.value = true;
        await props.vDS.data.map(function(pRow) { 
            // Select object
            if(pObjectIDs.indexOf(pRow.ID) > -1) {
                if(!pRow.state.isSelected) {
                    console.log("Add: " + pRow.ID);
                    props.vDS?.selectionControl.selectRange(true, pRow.index, pRow.index, true);
                    vSelectedRows.value.push({ID: pRow.ID, SelectedFromBIM: true});
                }
            } else if(pObjectIDs.indexOf(pRow.CopyOfObject_ID) > -1) {
                if(!pRow.state.isSelected) {
                    console.log("Add: " + pRow.CopyOfObject_ID);
                    props.vDS?.selectionControl.selectRange(true, pRow.index, pRow.index, true);
                    vSelectedRows.value.push({ID: pRow.CopyOfObject_ID, SelectedFromBIM: true});
                }
            } else if(pRow.state.isSelected) { // Remove select
                console.log("Remove: " + pRow.ID);
                // props.vDS?.selectionControl.selectRange(false, pRow.index, pRow.index);
                // vSelectedRows.value = vSelectedRows.value.filter(pItem => pItem.ID !== pRow.ID);
            }
        });
        vSelectingFromBIM.value = false;
    }

    function transformJson(objectsArr) {
        const floors = {};  
        vGroupColors.value = null;

        objectsArr.forEach(obj => {
            const { Name : name, BIMObject_ID : bimObjectId, AssetObject_ID: assetObjectId, IFCFile_ID : ifcFileId, R : red, G : green, B : blue } = obj;
            if (!ifcFileId) { return }
            if(!floors[name]) {
                floors[name] = { name, color: { r: red, g: green, b: blue }, objects: [] };
                vGroupColors.value = (vGroupColors.value ? vGroupColors.value + " | " : "") + "<div class='ml-2 d-inline-block'>" + name + "<div class='d-inline-block' style='background-color: rgb(" + red + "," + green + "," + blue + "); width: 11px; height: 11px;margin-left:2px;'></div></div>";
            }

            const foundObject = floors[name].objects.find(i => i.ifcFileId === ifcFileId);
            if (foundObject) {
                foundObject.bimObjectIds.push(bimObjectId);
                if (assetObjectId) {
                    foundObject.assetObjectIds ??= [];
                    foundObject.assetObjectIds.push(assetObjectId);
                }
            } else {
                const object = {
                    ifcFileId,
                    bimObjectIds: [bimObjectId],
                };
                if (assetObjectId) {
                    object.assetObjectIds = [assetObjectId];
                }
                floors[name].objects.push(object);
            }
        });

        return Object.values(floors);
    }

    async function loadBimData() {
        // if(props.vDS?.state.isLoading) { 
            // setTimeout(loadBimData, 200);
            // return;
        // }
        if(!props.vModelID) { return }
        console.log(1);
        
        // if ((useIframe.value && !vIframeLoaded.value) || !props.vModelID) { return }

        console.log(2);

        const vData = await procGetBOJson.execute({
            GroupBy: props.vFieldName, 
            Filter: props.vFilter,
            SpecifiedObjectJson: vSelectedRows.value ? JSON.stringify(vSelectedRows.value) : '[]'
        });
        
        console.log(3);

        if(!vData?.Table?.length) { return }

        console.log(4);

        vJsonData = JSON.parse(vData.Table[0]['JsonData']);
        vFragments = new Set(vJsonData.map(pRow => pRow.Fragment_ID));
        vIfcObjectIDs = vJsonData ? transformJson(vJsonData) : null;
        // console.log(vFragments);
        // console.log(vIfcObjectIDs);
        emit('groupColorsAdded', vGroupColors.value);

        const stateObject = {
            fragments: vFragments,
            ifcObjectIds: vIfcObjectIDs,
            rule: {
                hardSelect: true,
                isolate: props.vVisMode === 'isolated',
                baseColor: true,
            }
        };
        
        console.log(5);

        // vIframe.value?.iframeRef?.contentWindow.postMessage({
            // origin: vIframe.value.iframeRef.contentWindow.location.origin, 
            // channel_id: 'room_register',
            // event: 'loadViewerStateRequest', 
            // content: stateObject
        // });
        roomRegisterState.value = stateObject;

        console.log(6);
    }

    function onObjectSelected(e) {
        const objectIds = [];
        e?.assetObjectId && objectIds.push(e.assetObjectId);
        setSelectedObjects(objectIds);
    }
</script>

<template>
    <template v-if="useIframe">
        <IFrame  :src="`/bimviewer-nt?HideNav=true&Model=${props.vModelID}&Config=${10006}`" ref="vIframe" style="height:100%;width:100%;"/>
        <!-- <IFrame :src="`/bimviewer-rooms-fcm?HideNav=true&Model=${props.vModelID}`" ref="vIframe" style="height:100%;width:100%;"/> -->
    </template>
    <div v-else class="w-100 h-100 position-relative">
        <BimViewer v-if="vModelID" :modelId="vModelID" :configId="10006" :roomRegisterState="roomRegisterState" :onObjectSelected="onObjectSelected"/>
    </div>
</template>